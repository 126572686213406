const faqData = [
  {
    group: "about",
    items: [
      {
        question: "What is Monsai?",
        answer:
          "Monsai is an app dedicated to fostering personal growth. Through personalized daily lessons, quizzes, and tasks, it helps users advance in various life areas, including financial literacy, career development, and personal well-being.",
        slug: "what-is-monsai",
      },
      {
        question: "Who is Monsai for?",
        answer:
          "Monsai is designed for anyone looking to improve themselves, whether you're aiming for career advancement, personal development, or acquiring new life skills. It's your personal guide to achieving your goals and enhancing your life quality.",
        slug: "who-is-monsai-for",
      },
      {
        question: "What can I achieve with Monsai?",
        answer: `
          <span>
            <p>
              With Monsai, you can take on a journey of
              self-improvement and personal development,
              tailored specifically to your goals. The app
              helps you cultivate new skills, enhance your
              knowledge, and build positive habits through
              personalized daily content.
            </p>
            <ul>
              <li>
                <b>Finance:</b> Master budgeting basics,
                learn investment strategies, and
                understand debt management. Save for the
                future, improve financial literacy, and
                achieve financial independence.
              </li>
              <li>
                <b>Career Growth:</b> Enhance leadership
                skills, boost productivity, and excel in
                communication. Navigate career
                transitions, improve networking, and
                master effective teamwork.
              </li>
              <li>
                <b>Personal Development:</b> Cultivate
                mindfulness, manage stress, and foster
                emotional intelligence. Build resilience,
                enhance creativity, and improve personal
                relationships.
              </li>
              <li>
                <b>Health and Wellness:</b> Adopt healthy
                habits, learn about nutrition, and commit
                to regular exercise. Focus on mental
                health, practice self-care, and achieve a
                balanced lifestyle.
              </li>
              <li>
                <b>Relationships:</b> Enhance
                communication skills, deepen empathy, and
                strengthen connections. Navigate conflict
                resolution, build trust, and cultivate
                meaningful personal and professional
                relationships.
              </li>
            </ul>
            <p>
              Each journey is complemented by daily
              actionable content, encouraging not just
              learning but real-life application,
              empowering you to achieve tangible progress
              in areas that matter most to you.
            </p>
          </span>
          `,
        slug: "what-can-i-achieve-with-monsai",
      },
      {
        question: "Is Monsai available outside of the US?",
        answer:
          "Currently, Monsai is only available in the United States. We are planning to expand our availability to other countries, so please stay tuned for future updates!",
        slug: "is-monsai-available-outside-of-the-us",
      },
      {
        question: "How does Monsai personalize my experience?",
        answer:
          "Monsai uses an initial onboarding questionnaire to understand your goals and preferences. Based on your responses, it tailors your daily content, ensuring that the lessons, quizzes, and tasks are aligned with your personal growth objectives.",
        slug: "how-does-monsai-personalize-my-experience",
      },
    ],
  },
  {
    group: "account",
    items: [
      {
        question: "How do I delete my account?",
        answer: `
          <span>
            <p>
              To delete your account, follow these steps
              inside the app:
            </p>
            <ul>
              <li>
                Navigate to your profile (top-left icon)
              </li>
              <li>Tap "Account Settings"</li>
              <li>
                Scroll down and tap "Delete account"
              </li>
              <li>
                You can give us feedback and tap "Submit
                Feedback" OR you tap "Skip Feedback &
                Delete"
              </li>
              <li>Confirm delete</li>
            </ul>
            <p>
              <b>NOTE:</b> Deleting your account will erase all
              your data permanently, but doesn't
              automatically cancel your premium
              subscription if you have one.
            </p>
          </span>
          `,
        slug: "how-do-i-delete-my-account",
      },
      {
        question: "How do I request my data?",
        answer: `
          <span>
            <p>
              To request a copy of your data, you need to
              send your request from inside the app.
            </p>
            <ul>
              <li>
                Navigate to your profile (top-left icon)
              </li>
              <li>Tap "Contact Us"</li>
              <li>
                Choose "Request my data" from the dropdown
                menu
              </li>
              <li>
                Write a message if needed, and hit Submit
              </li>
            </ul>
            <p>
              You will receive a copy of your data within
              30 days
            </p>
          </span>
        `,
        slug: "how-do-i-request-my-data",
      },
    ],
  },
  {
    group: "subscription",
    items: [
      {
        question: "How does the free trial work?",
        answer:
          "The free trial gives you full access to all of Monsai's features for the duration of the trial period. You won't be charged during the trial period, and you can cancel at any time before the trial ends to avoid subscription fees. After the trial ends, it will automatically convert into the agreed subscription.",
        slug: "how-does-the-free-trial-work",
      },
      {
        question: "How do I cancel my Monsai premium subscription?",
        answer: `
          <span>
            <p>
              <b>NOTE:</b> Uninstalling the app or deleting your
              Monsai account will not automatically stop
              your Monsai Premium subscription. To stop
              the Monsai Premium subscription and the
              recurring payments, you need to cancel the
              subscription.
            </p>
            <p>
              The subscription you've purchased remains
              active until it reaches its expiry date,
              even when you cancel it right after the
              purchase.
            </p>
            <b>Apple App Store:</b>
            <p>
              Subscriptions purchased through the Apple
              App Store are fully handled by Apple.
            </p>
            <ul>
              <li>
                To cancel an Apple App Store subscription,
                follow the instructions in the Apple
                cancelation page by 
                <a
                  href="https://support.apple.com/en-us/HT202039"
                  target="_blank"
                >
                  clicking here
                </a>.
              </li>
            </ul>
            <b>Google Play Store:</b>
            <p>
              Subscriptions purchased through the Google Play Store
              are fully handled by Google.
            </p>
            <ul>
              <li>
                To cancel a Google Play subscription,
                visit the Google cancelation page by 
                <a
                  href="https://play.google.com/store/account/subscriptions"
                  target="_blank"
                >
                  clicking here
                </a>. For more information, please see <a
                href="https://support.google.com/googleplay/answer/2476088?hl=en"
                target="_blank"
              >Subscriptions on Google Play.</a>
              </li>
            </ul>
          </span>
        `,
        slug: "how-do-i-cancel-my-monsai-premium-subscription",
      },
      {
        question: "Can I get a refund for my subscription?",
        answer: `
          <span>
            <p>
              If you're not satisfied with Monsai Premium
              and wish to request a refund, please contact
              the app store where you made the purchase,
              as refunds are processed according to their
              policies.
            </p>
            <ul>
              <li>
                <a
                  href="https://support.apple.com/en-us/118223"
                  target="_blank"
                >
                  How to receive a refund on iOS
                </a>
              </li>
              <li>
                <a
                  href="https://support.google.com/googleplay/answer/2479637?hl=en"
                  target="_blank"
                >
                  How to receive a refund on Android
                </a>
              </li>
            </ul>
          </span>
        `,
        slug: "can-i-get-a-refund-for-my-subscription",
      },
      {
        question: "What happens to my data if I cancel my subscription?",
        answer:
          "If you cancel your subscription, you'll still have access to your data and premium features until the end of your billing cycle. After that, your account will revert to the free version, but your data will be retained in case you decide to subscribe again in the future.",
        slug: "what-happens-to-my-data-if-i-cancel-my-subscription",
      },
      {
        question: "How do I update my billing information?",
        answer:
          "To update your billing information, please do so through your respective app store account settings. Monsai does not process payments directly and thus does not store your billing information.",
        slug: "how-do-i-update-my-billing-information",
      },
      {
        question: "Will my subscription automatically renew?",
        answer:
          "Yes, your Monsai Premium subscription will automatically renew at the end of each billing cycle. You can manage or cancel the auto-renewal feature in your app store account settings.",
        slug: "will-my-subscription-automatically-renew",
      },
    ],
  },
  {
    group: "app",
    items: [
      {
        question: "How often should I use Monsai?",
        answer:
          "For best results, we recommend engaging with Monsai daily. The app is designed to provide manageable daily tasks and lessons that cumulatively contribute to significant growth over time.",
        slug: "how-often-should-i-use-monsai",
      },
      {
        question: "How does the daily personalized content work?",
        answer:
          "Every day, Monsai provides new content tailored to your growth objectives, including lessons, quizzes, and tasks. These are based on your goals and progress, ensuring you receive relevant and challenging material to aid your development.",
        slug: "how-does-the-daily-personalized-content-work",
      },
      {
        question: "How do I use the habit tracker?",
        answer:
          "The habit tracker allows you to set and monitor daily habits aligned with your goals. Add new habits, set reminders, and track your consistency and progress over time to build and maintain beneficial routines.",
        slug: "how-do-i-use-the-habit-tracker",
      },
      {
        question: "What is the journal feature, and how do I use it?",
        answer:
          "The journal feature offers a space for reflection and personal insights. Use it to jot down thoughts, summarize what you've learned, or reflect on your progress. Regular journaling can enhance your learning experience and support your personal growth journey.",
        slug: "what-is-the-journal-feature-and-how-do-i-use-it",
      },
      {
        question:
          "What should I do if I experience technical issues with the app?",
        answer: `
          <span>
            <p>
              If you encounter any technical difficulties:
            </p>
            <ul>
              <li>
                Contact us directly within the app by
                going to <b>Profile -> Contact Us</b> 
                and Choose <b>"Technical Difficulty"</b> 
                from the dropdown menu
              </li>
              <li>
                Or contact us at 
                <a href="mailto: support@monsaiapp.com">
                  support@monsaiapp.com
                </a>
              </li>
            </ul>
            <p>
              We're here to help resolve any issues and
              improve your experience.
            </p>
          </span>
        `,
        slug: "what-should-i-do-if-i-experience-technical-issues-with-the-app",
      },
    ],
  },
]

module.exports = faqData
// export default faqData
