import React from "react"
import { PageWrapper } from "~components/Core"
import SearchSection from "~sections/utility/Faq/SearchSection"
import FaqSection from "~sections/utility/Faq/FaqSection"
import FooterSection from "~sections/utility/Footer"

export default function FaqPage() {
  const header = {
    headerClasses: "position-relative",
  }
  return (
    <PageWrapper innerPage={true} headerConfig={header}>
      <SearchSection />
      <FaqSection />
      <FooterSection />
    </PageWrapper>
  )
}
