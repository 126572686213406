import React from "react"
import { Link } from "gatsby"
import { Accordion, Col, Container, Nav, Row, Tab } from "react-bootstrap"
import { AccordionItemFull } from "~components/Accordion"
import TabList from "./Component/Tab"
import Faq from "./style"
import faqData from "~data/FaqData"

export default function FaqSection() {
  return (
    <Faq>
      <Container>
        <Faq.Body>
          <Faq.TabWrapper
            as={Tab.Container}
            id="left-tabs-example"
            defaultActiveKey="about"
          >
            <Row className="justify-content-center">
              <Col className="col-xl-10">
                <Row className="justify-content-center justify-md-content-start">
                  <div className="col-xl-3 col-lg-4 col-md-5 col-xs-8 mb-6 mb-lg-0">
                    <TabList as={Nav}>
                      <Nav.Link
                        eventKey="about"
                        className="nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                      >
                        About
                      </Nav.Link>
                      <Nav.Link
                        eventKey="account"
                        className="col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                      >
                        Account
                      </Nav.Link>
                      <Nav.Link
                        eventKey="subscription"
                        className="widget widget--feature nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                      >
                        Subscription
                      </Nav.Link>
                      <Nav.Link
                        eventKey="app"
                        className="widget widget--feature nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                      >
                        App
                      </Nav.Link>
                    </TabList>
                  </div>
                  <Col className="col-xl-9 col-lg-8 col-md-11">
                    <Tab.Content className="tab-content tab-content--feature">
                      {faqData.map(group => (
                        <Tab.Pane eventKey={group.group} key={group.group}>
                          <Faq.Accordion>
                            <Accordion defaultActiveKey={group.items[0].slug}>
                              {group.items.map(item => (
                                <AccordionItemFull
                                  question={item.question}
                                  answer={item.answer}
                                  accKey={item.slug}
                                  key={item.slug}
                                />
                              ))}
                            </Accordion>
                          </Faq.Accordion>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                    <Faq.ButtonGroup mt="50px">
                      <p>
                        Haven’t got your answer? Contact us at
                        <a
                          className="btn-link text-readical-red ms-2"
                          href="mailto: support@monsaiapp.com"
                        >
                          support@monsaiapp.com
                        </a>
                      </p>
                    </Faq.ButtonGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Faq.TabWrapper>
        </Faq.Body>
      </Container>
    </Faq>
  )
}
